/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth } from '../../firebase/firebase';
import { BaseURL } from '../../configs/config';


export const enquiryApi = createApi({
  reducerPath: 'enquiryApi',
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + "admin/enquiries/",
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken();
      const orgId = getState().prefs.selectedOrgId;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (orgId) {
        headers.set('org-id', orgId);
      }
      return headers;
    },
  }),
  tagTypes: ['Enquiry'],
  endpoints: (builder) => ({
    getEnquiries: builder.query({
      query: (args) => {
        const params = {};
        if (args?.sort) {
          params['sort'] = args.sort;
        }
        if (args?.limit) {
          params.limit = args.limit;
        }
        params.marker = args?.marker;
        return {
          url: '/',
          params: params,
        };
      },

      transformResponse: (response) => {
        return response;
      },
      providesTags: ['Enquiry'],
    }),
    markEnquiryRead: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'Put',
      }),
      invalidatesTags: ['Enquiry'],
    }),
  }),
});

export const { useGetEnquiriesQuery, useMarkEnquiryReadMutation, useLazyGetEnquiriesQuery } = enquiryApi;
