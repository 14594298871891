import { createSlice } from '@reduxjs/toolkit'
import { auth } from '../../firebase/firebase'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userProfile: null,
    orgList: []
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload
    },
    setOrgList: (state, action) => {
      state.orgList = action.payload
    },
    logout: (state, action) => {
      auth.signOut()
      state.orgList = []
      state.userProfile = null
    }
  }
})

export const { setUserProfile, setOrgList, logout } = userSlice.actions
export default userSlice.reducer
