/**
 *
 * customer api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const customerApi = createApi({
  reducerPath: 'customerApi',
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/customers/',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['Customer'],
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (args) => {
        console.log('CustomerTab: arg: ', JSON.stringify(args))
        const params = {}
        if (args?.sort) {
          params['sort'] = args.sort
        }
        if (args?.limit) {
          params.limit = args.limit
        }
        if (args?.marker) {
          params.marker = args?.marker
        }
        return {
          url: '/',
          params: params
        }
      },
      transformResponse: (response) => {
        console.log('CustomerTab: get customers response: ' + JSON.stringify(response))
        return response.data
      },
      providesTags: ['Customer']
    }),
    addCustomer: builder.mutation({
      query: (body) => ({
        url: '/',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['Customer']
    }),
    bulkUploadCustomer: builder.mutation({
      query: (body) => ({
        url: 'bulkUpload',
        method: 'Post',
        body
      }),
      invalidatesTags: ['Customer']
    })
  })
})

export const {
  useGetCustomersQuery,
  useAddCustomerMutation,
  useBulkUploadCustomerMutation,
  useLazyGetCustomersQuery
} = customerApi
