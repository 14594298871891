// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { persistStore } from 'redux-persist'
import { catalogApi } from './services/catalog'
import { userApi } from './services/users'
import { configApi } from './services/config'
import { couponsApi } from './services/coupons'
import { customerApi } from './services/customer'
import { enquiryApi } from './services/enquiry'
import { orderApi } from './services/orders'
import { subscriptionApi } from './services/subscriptions'
import { bulkUploadApi } from './services/bulkUpload'
import { shippingApi } from './services/shipping'
import { paymentOptionsApi } from './services/paymentOptions'
import { shippingProviderApi } from './services/shippingProvider'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      catalogApi.middleware,
      userApi.middleware,
      customerApi.middleware,
      enquiryApi.middleware,
      orderApi.middleware,
      configApi.middleware,
      // analyticsApi.middleware,
      couponsApi.middleware,
      shippingProviderApi.middleware,
      shippingApi.middleware,
      bulkUploadApi.middleware,
      subscriptionApi.middleware,
      paymentOptionsApi.middleware
    )
  }
})

export const persistor = persistStore(store)
setupListeners(store.dispatch)
