/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth } from '../../firebase/firebase';
import { BaseURL } from '../../configs/config';

export const couponsApi = createApi({
  reducerPath: 'couponsApi',
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + "admin/coupons",
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken();
      const orgId = getState().prefs.selectedOrgId;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (orgId) {
        headers.set('org-id', orgId);
      }
      return headers;
    },
  }),
  tagTypes: ['Coupons'],
  endpoints: (builder) => ({
    getCoupons: builder.query({
      query: () => '',
      transformResponse: (response) => response.data,
      providesTags: ['Coupons'],
    }),
    getCouponById: builder.query({
      query: (id) => `/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Coupons'],
    }),
    checkCouponCode: builder.query({
      query: (params) => ({
        url: 'isCouponCodePresent',
        method: 'Get',
        params,
      }),
      transformResponse: (response) => response.data,
    }),
    createCoupon: builder.mutation({
      query: (body) => ({
        url: '',
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Coupons'],
      transformResponse: (response) => response,
    }),
    editCoupon: builder.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'Put',
        body,
      }),
      invalidatesTags: ['Coupons'],
      transformResponse: (response) => response,
    }),
    removeCoupon: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'Delete',
      }),
      invalidatesTags: ['Coupons'],
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useGetCouponsQuery,
  useLazyCheckCouponCodeQuery,
  useCreateCouponMutation,
  useEditCouponMutation,
  useRemoveCouponMutation,
  useGetCouponByIdQuery,
  useLazyGetCouponByIdQuery
} = couponsApi;
