/**
 *
 * shipping api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const shippingApi = createApi({
  reducerPath: 'shippingApi',
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/shipping_methods/',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['ShippingMethods'],
  endpoints: (builder) => ({
    getAvailableShippingMethods: builder.query({
      query: () => '/',
      transformResponse: (response) => {
        return response.data
      },
      providesTags: ['ShippingMethods']
    }),
    getShippingMethodDetails: builder.query({
      query: (id) => `/${id}`,
      transformResponse: (response) => {
        return response.data
      },
      providesTags: ['ShippingMethods']
    }),
    addShippingMethod: builder.mutation({
      query: (body) => ({
        url: '/',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['ShippingMethods']
    }),
    updateShippingMethod: builder.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'Put',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['ShippingMethods']
    }),
    removeShippingMethod: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'Delete'
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['ShippingMethods']
    })
  })
})

export const {
  useAddShippingMethodMutation,
  useGetAvailableShippingMethodsQuery,
  useGetShippingMethodDetailsQuery,
  useRemoveShippingMethodMutation,
  useUpdateShippingMethodMutation,
  useLazyGetShippingMethodDetailsQuery
} = shippingApi
