/**
 *
 * paymentOptions api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const shippingProviderApi = createApi({
  reducerPath: 'shippingProviderApi',
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/shippingProvider/',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['ShippingProvider', 'ShippingProviderConfigs'],
  endpoints: (builder) => ({
    getShippingProviderConfigs: builder.query({
      query: () => 'shippingProviderConfigs',
      transformResponse: (response) => {
        return response.data
      },
      providesTags: ['ShippingProviderConfigs']
    }),
    getShippingProvidersInOrg: builder.query({
      query: () => 'shippingProvidersInOrg',
      transformResponse: (response) => {
        return response.data
      },
      providesTags: ['ShippingProvider']
    }),
    addShippingProviderInOrg: builder.mutation({
      query: (body) => ({
        url: 'shippingProvidersInOrg',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['ShippingProvider']
    }),
    updateShippingProviderInOrg: builder.mutation({
      query: ({ id, body }) => ({
        url: `shippingProvidersInOrg/${id}`,
        method: 'Put',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['ShippingProvider']
    }),
    removeShippingProviderInOrg: builder.mutation({
      query: (id) => ({
        url: `shippingProvidersInOrg/${id}`,
        method: 'Delete'
      }),
      invalidatesTags: ['ShippingProvider']
    }),
    getPickupAddress: builder.query({
      query: (shippingProviderId) => ({
        url: 'pickupAddress',
        method: 'Get',
        params: { shippingProviderId }
      }),
      transformResponse: (response) => {
        return response.data
      }
    }),
    getAvailableCourier: builder.query({
      query: (params) => ({
        url: 'getAvailableCourier',
        method: 'Get',
        params
      }),
      transformResponse: (response) => {
        response.data.courier_data.sort((a, b) => a.rate - b.rate)
        return response.data
      }
    }),
    createShippingProviderOrder: builder.mutation({
      query: (body) => ({
        url: 'order',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response
    }),
    generateAWB: builder.mutation({
      query: (body) => ({
        url: 'generateAWB',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response
    }),
    schedulePickup: builder.mutation({
      query: (body) => ({
        url: 'requestShipmentPickup',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response
    }),
    downloadDocument: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: 'Post',
        body
      }),
      transformResponse: (response) => response
    })
  })
})

export const {
  useGetShippingProviderConfigsQuery,
  useGetShippingProvidersInOrgQuery,
  useAddShippingProviderInOrgMutation,
  useUpdateShippingProviderInOrgMutation,
  useRemoveShippingProviderInOrgMutation,
  useGetPickupAddressQuery,
  useGetAvailableCourierQuery,
  useCreateShippingProviderOrderMutation,
  useGenerateAWBMutation,
  useSchedulePickupMutation,
  useDownloadDocumentMutation
} = shippingProviderApi
