/**
 *
 * catalog api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  refetchOnReconnect: true,
  refetchOnFocus: false,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/catalog/',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['Collection', 'Product', 'Category', 'Image'],
  endpoints: (builder) => ({
    //Collections End points
    getCollections: builder.query({
      query: () => 'collections',
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Collection',
                id
              })),
              'Collection'
            ]
          : ['Collection'],
      transformResponse: (response) => response.data
    }),
    getCollectionById: builder.query({
      query: (id) => `collections/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Collection']
    }),
    getProductsinCollection: builder.query({
      query: (collectionId) => `collections/${collectionId}/products`,
      transformResponse: (response) => response.data,
      providesTags: ['Product', 'Collection']
    }),
    addCollection: builder.mutation({
      query: (body) => ({
        url: 'collections',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Collection']
    }),
    updateCollection: builder.mutation({
      query: ({ collectionId, body }) => ({
        url: `collections/${collectionId}`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Collection']
    }),
    deleteCollection: builder.mutation({
      query: (id) => ({
        url: `collections/${id}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Collection']
    }),

    //Products endpoints
    getProducts: builder.query({
      // query: () => 'products',
      query: (args) => {
        const params = {}
        if (args?.sort) {
          params['sort'] = args.sort
        }
        if (args?.limit) {
          params.limit = args.limit
        }
        if (args?.marker) {
          params.marker = args?.marker
        }
        return {
          url: '/products',
          params: params
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Product']
    }),
    getProductById: builder.query({
      query: (productId) => `products/${productId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Product']
    }),
    addProduct: builder.mutation({
      query: (body) => ({
        url: 'products',
        method: 'Post',
        body
      }),
      transformResponse: (response) => response,
      invalidatesTags: ['Product']
    }),
    addSampleProduct: builder.mutation({
      query: (orgId) => ({
        url: `products/${orgId}/sampleProduct`,
        method: 'Post'
      }),
      transformResponse: (response) => response
    }),
    bulkUploadProduct: builder.mutation({
      query: (body) => ({
        url: 'products/bulkUpload',
        method: 'Post',
        body
      })
    }),
    updateProduct: builder.mutation({
      query: ({ productId, body }) => ({
        url: `products/${productId}`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Product']
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `products/${productId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Product', 'Collection']
    }),
    checkProductSlugIsUnique: builder.query({
      query: (slug) => `products/checkSlug?slug=${slug}`,
      transformResponse: (response) => response.data
    }),
    //Product Images Endpoints
    getProductImages: builder.query({
      query: (productId) => `products/${productId}/images`,
      transformResponse: (response) => response.data,
      providesTags: ['Image']
    }),
    getProductImageByID: builder.query({
      query: ({ productId, imageId }) => `products/${productId}/images/${imageId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Image']
    }),
    addProductImage: builder.mutation({
      query: ({ productId, body }) => ({
        url: `products/${productId}/images`,
        method: 'Post',
        body
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Product']
    }),
    addMultipleProductImages: builder.mutation({
      query: ({ productId, body }) => ({
        url: `products/${productId}/multipleImages`,
        method: 'Post',
        body
      }),
      transformResponse: (response) => response.data
      // invalidatesTags: ['Image'],
    }),
    updateProductImage: builder.mutation({
      query: ({ productId, imageId, body }) => ({
        url: `products/${productId}/images/${imageId}`,
        method: 'Put',
        body
      }),
      transformResponse: (response) => response.data
      // invalidatesTags: ['Image'],
    }),
    deleteProductImage: builder.mutation({
      query: ({ productId, imageId }) => ({
        url: `products/${productId}/images/${imageId}`,
        method: 'Delete'
      })
    }),
    updateProductVariant: builder.mutation({
      query: ({ productId, variantId, body }) => ({
        url: `products/${productId}/variants/${variantId}`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Product']
    }),
    removeProductVariant: builder.mutation({
      query: ({ productId, variantId }) => ({
        url: `products/${productId}/variants/${variantId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Product']
    }),
    removeProductOption: builder.mutation({
      query: ({ productId, optionId }) => ({
        url: `products/${productId}/options/${optionId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Product']
    }),
    updateProductOption: builder.mutation({
      query: ({ productId, optionId, body }) => ({
        url: `products/${productId}/options/${optionId}`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Product']
    }),

    //Categories endpoints
    getCategories: builder.query({
      query: (args) => {
        const params = {}
        if (args?.sort) {
          params['sort'] = args.sort
        }
        if (args?.limit) {
          params.limit = args.limit
        }
        if (args?.market) {
          params.marker = args?.marker
        }
        return {
          url: '/categories',
          params: params
        }
      },
      providesTags: ['Category'],
      transformResponse: (response) => {
        // console.log('response: ' + JSON.stringify(response))
        return response.data
      }
    }),
    getCategoryById: builder.query({
      query: (id) => `categories/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Category']
    }),
    getProductsInCategory: builder.query({
      query: (args) => {
        const { categoryId } = args
        // return `categories/${categoryId}/products`;
        const params = {}
        if (args?.sort) {
          params['sort'] = args.sort
        }
        if (args?.limit) {
          params.limit = args.limit
        }
        if (args?.marker) {
          params.marker = args?.marker
        }
        return {
          url: `categories/${categoryId}/products`,
          params: params
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Product', 'Category']
    }),
    addCategory: builder.mutation({
      query: (body) => ({
        url: 'categories',
        method: 'Post',
        body
      }),
      transformResponse: (response) => {
        console.log('response: ' + JSON.stringify(response))
        return response
      },
      invalidatesTags: ['Category']
    }),
    updateCategory: builder.mutation({
      query: ({ categoryId, body }) => ({
        url: `categories/${categoryId}`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Category']
    }),
    deleteCategory: builder.mutation({
      query: (categoryId) => ({
        url: `categories/${categoryId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Category', 'Product']
    }),
    checkCategorySlugIsUnique: builder.query({
      query: (slug) => `categories/checkSlug?slug=${slug}`,
      transformResponse: (response) => response.data
    })
  })
})

export const {
  //collections functions
  useGetCollectionsQuery,
  useGetCollectionByIdQuery,
  useGetProductsinCollectionQuery,
  useAddCollectionMutation,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
  //Products functions
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductByIdQuery,
  useLazyGetProductByIdQuery,
  useAddProductMutation,
  useAddSampleProductMutation,
  useBulkUploadProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useLazyCheckProductSlugIsUniqueQuery,
  useUpdateProductVariantMutation,
  useRemoveProductVariantMutation,
  useUpdateProductOptionMutation,
  useRemoveProductOptionMutation,

  //Product Images functions
  useGetProductImagesQuery,
  useLazyGetProductImagesQuery,
  useGetProductImageByIDQuery,
  useAddProductImageMutation,
  useAddMultipleProductImagesMutation,
  useUpdateProductImageMutation,
  useDeleteProductImageMutation,
  //Category functions
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useLazyGetCategoryByIdQuery,
  useGetProductsInCategoryQuery,
  useLazyGetProductsInCategoryQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyCheckCategorySlugIsUniqueQuery
} = catalogApi
