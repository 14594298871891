import { createSlice } from '@reduxjs/toolkit'
import { generateFirestoreDocId } from '../../firebase/firebase'
import * as variantHelper from '../../views/pages/catalog/variantHelper'

export const productVariantsSlice = createSlice({
  name: 'productVariants',
  initialState: {
    options: [],
    variants: [],
    tax_id: null
  },
  reducers: {
    createOptionNGenerateVariants: (state, action) => {
      const optionTitle = action.payload.title
      const optionValues = action.payload.values
      const autoGeneratedId = generateFirestoreDocId()

      console.log(
        'addOption: optionTitle:' + optionTitle + ' optionValues: ' + JSON.stringify(optionValues)
      )

      const updatedOptions = [
        ...state.options,
        {
          title: optionTitle,
          values: optionValues,
          id: autoGeneratedId
        }
      ]

      console.log('addOption: state.options:' + JSON.stringify(state.options))
      console.log('addOption: updatedOptions:' + JSON.stringify(updatedOptions))

      state.options = updatedOptions
      state.variants = variantHelper.createVariantsFromOptions(updatedOptions)

      console.log('state options post: ' + JSON.stringify(state.options))
      console.log('state variants post: ' + JSON.stringify(state.variants))
    },
    updateOptionWithIdNGenerateVariants: (state, action) => {
      const { optionId, title, values } = action.payload
      const updatedOptions = state.options.map((o) => {
        if (o.id !== optionId) {
          return o
        }
        // update option
        return {
          ...o,
          title: title,
          values: values
        }
      })

      state.options = updatedOptions
      state.variants = variantHelper.createVariantsFromOptions(updatedOptions)
    },
    addSavedOptionsVariants: (state, action) => {
      const { options, variants } = action.payload
      const optionsPayload = options.map((o) => {
        return {
          id: o.id,
          title: o.title,
          values: o.values.map((v) => ({
            option_id: o.id,
            option_title: o.title,
            value_title: v.title,
            value_id: v.id
          }))
        }
      })

      const variantsPayload = variants.map((v) => {
        return {
          ...v,
          config: v.config.map((c) => ({
            option_id: c.option_id,
            option_title: c.option_title,
            value_title: c.value_title,
            value_id: c.value_id
          }))
        }
      })

      console.log('addOption: state.options:' + JSON.stringify(state.options))
      console.log('addOption: updatedOptions:' + JSON.stringify(optionsPayload))

      state.options = optionsPayload
      state.variants = variantsPayload
    },
    removeOptionWithId: (state, action) => {
      const optionId = action.payload.optionId
      const filteredOptions = state.options.filter((o) => o.id !== optionId)
      state.options = filteredOptions
      state.variants = variantHelper.createVariantsFromOptions(filteredOptions)
    },
    clearAllOptions: (state, action) => {
      state.options = []
      state.variants = []
    },
    updateVariantWithId: (state, action) => {
      const variantId = action.payload.variantId
      const data = action.payload.data
      const updatedVariants = state.variants.map((v) => {
        if (v.id !== variantId) {
          return v
        }
        // update variant
        return {
          ...v,
          ...data
        }
      })

      state.variants = updatedVariants
    },
    removeVariantWithId: (state, action) => {
      const variantId = action.payload.variantId
      const filteredArray = state.variants.filter((o) => o.id !== variantId)
      state.variants = filteredArray
    },
    updateTax: (state, action) => {
      const tax_id = action.payload.tax_id
      state.tax_id = tax_id
    },
    resetState: (state) => {
      state.options = []
      state.variants = []
      state.tax_id = null
    }
  }
})

export const {
  createOptionNGenerateVariants,
  removeOptionWithId,
  clearAllOptions,
  removeVariantWithId,
  resetState,
  addSavedOptionsVariants,
  updateVariantWithId,
  updateOptionWithIdNGenerateVariants,
  updateTax
} = productVariantsSlice.actions

export default productVariantsSlice.reducer
