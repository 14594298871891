import { createSlice } from '@reduxjs/toolkit'

export const preferenceSlice = createSlice({
  name: 'prefs',
  initialState: {
    selectedOrgId: ''
  },
  reducers: {
    setSelectedOrgId: (state, action) => {
      state.selectedOrgId = action.payload
    }
  }
})

export const { setSelectedOrgId } = preferenceSlice.actions

export default preferenceSlice.reducer
