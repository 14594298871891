import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const orderApi = createApi({
  reducerPath: 'orderApi',
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/orders',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['Order'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (args) => {
        const params = {}
        if (args?.sort) {
          params['sort'] = args.sort
        }
        if (args?.limit) {
          params.limit = args.limit
        }
        if (args?.marker) {
          params.marker = args?.marker
        }
        return {
          url: '/',
          params: params
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Order']
    }),
    getOrderById: builder.query({
      query: (id) => `/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ['Order']
    }),
    updateOrderStatus: builder.mutation({
      query: ({ orderId, body }) => ({
        url: `/${orderId}/status`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Order']
    }),
    orderShipped: builder.mutation({
      query: ({ orderId, body }) => ({
        url: `/${orderId}/shipping/shipped`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Order']
    }),
    orderDelivered: builder.mutation({
      query: ({ orderId, body }) => ({
        url: `/${orderId}/shipping/delivered`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Order']
    }),
    recordManualPayment: builder.mutation({
      query: ({ orderId, body }) => ({
        url: `/${orderId}/payments/manualPayment`,
        method: 'Put',
        body
      }),
      invalidatesTags: ['Order'],
      transformResponse: (response) => response
    }),
    getOrderPayments: builder.query({
      query: (id) => `/${id}/payments`,
      transformResponse: (response) => response.data,
      providesTags: ['Order']
    })
  })
})

export const {
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useUpdateOrderStatusMutation,
  useOrderShippedMutation,
  useOrderDeliveredMutation,
  useRecordManualPaymentMutation,
  useGetOrderPaymentsQuery,
  useLazyGetOrderPaymentsQuery
} = orderApi
