import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const bulkUploadApi = createApi({
  reducerPath: 'bulkUploadApi',
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin/bulkUpload',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['BulkUploads'],
  endpoints: (builder) => ({
    initiateBulkUpload: builder.mutation({
      query: (body) => ({
        url: '/initiate',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return response.data
      },
      invalidatesTags: ['BulkUploads']
    }),
    getBulkUploadsList: builder.query({
      query: () => '/list',
      transformResponse: (response) => response.data,
      providesTags: ['BulkUploads']
    })
  })
})

export const { useInitiateBulkUploadMutation, useGetBulkUploadsListQuery } = bulkUploadApi
