/* eslint-disable quotes */
/**
 *
 * subscription api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin',
    prepareHeaders: async (headers, { getState }) => {
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        console.log('Using token: ' + token)
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: ['Subscriptions'],
  endpoints: (builder) => ({
    getActiveSubscription: builder.query({
      query: () => ({
        url: 'subscriptions/active',
        method: 'GET'
      }),
      transformResponse: (response) => {
        return response.data
      },
      providesTags: ['Subscriptions']
    }),
    requestPlanUpgrade: builder.mutation({
      query: (body) => ({
        url: 'subscriptions/requestPlanUpgrade',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return response.data
      }
    }),
    upgradePlan: builder.mutation({
      query: (body) => ({
        url: 'subscriptions/upgradePlan',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return response.data
      },
      invalidatesTags: ['Subscriptions']
    }),
    verifyPlanPayment: builder.mutation({
      query: (body) => ({
        url: 'subscriptions/verifyPlanPayment',
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return response.data
      },
      invalidatesTags: ['Subscriptions']
    })
  })
})

export const {
  useLazyGetActiveSubscriptionQuery,
  useRequestPlanUpgradeMutation,
  useUpgradePlanMutation,
  useVerifyPlanPaymentMutation
} = subscriptionApi
