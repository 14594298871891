import { v4 as uuidv4 } from 'uuid'
uuidv4()
export const getBlobFromFileAsync = async (file) => {
  const response = await fetch(file)
  const blob = await response.blob()
  return blob
}

export const getUUID = () => {
  return uuidv4()
}
