/* eslint-disable quotes */
/**
 *
 * user api
 *
 */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../../firebase/firebase'
import { BaseURL } from '../../configs/config'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'admin',
    prepareHeaders: async (headers, { getState }) => {
      console.log('userApi -> calling prepareHeaders')
      const token = await auth.currentUser.getIdToken()
      const orgId = getState().prefs.selectedOrgId
      if (token) {
        console.log('Using token: ' + token)
        headers.set('Authorization', `Bearer ${token}`)
      }
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  tagTypes: [
    'Organization',
    'User',
    'Testimonials',
    'Banners',
    'NewsLetterEmails',
    'PaymentOptions',
    'Taxes'
  ],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: 'users/',
        method: 'GET'
      }),
      transformResponse: (response) => {
        // console.log('userApi: getUser response: ' + JSON.stringify(response))
        return response.data
      },
      providesTags: ['User']
    }),
    createUserProfile: builder.mutation({
      query: (body) => {
        return {
          url: 'users/create',
          method: 'POST',
          body
        }
      },
      transformResponse: (response) => response.data,
      invalidatesTags: ['User']
    }),
    getOrgs: builder.query({
      query: () => `/organizations/currentUser`,
      transformResponse: (response) => {
        // console.log('get user orgs: response: ' + JSON.stringify(response))

        return response.data
      },
      providesTags: ['Organization']
    }),
    getOrg: builder.query({
      query: (orgId) => `/organizations/${orgId}`,
      transformResponse: (response) => {
        // console.log('getOrg: transforming' + response)
        return response.data
      },
      providesTags: ['Organization']
    }),
    checkSlugIsUnique: builder.query({
      query: (slug) => `/organizations/checkSlug?slug=${slug}`,
      transformResponse: (response) => response.data
    }),
    createOrg: builder.mutation({
      query: (body) => {
        return {
          url: '/organizations/create',
          method: 'POST',
          body
        }
      },
      transformResponse: (response) => response,
      invalidatesTags: ['Organization']
    }),
    updateOrg: builder.mutation({
      query: (body) => {
        return {
          url: '/organizations/update',
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['Organization']
    }),
    addSampleData: builder.mutation({
      query: () => ({
        url: '/organizations/addSampleData',
        method: 'Post'
      }),
      transformResponse: (response) => response
    }),
    updatePaymentOptions: builder.mutation({
      query: (body) => {
        return {
          url: '/organizations/paymentOptions',
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['Organization']
    }),
    addUpdateSubscription: builder.mutation({
      query: (planId) => {
        return {
          url: `/organizations/subscriptions/${planId}`,
          method: 'PUT'
        }
      },
      invalidatesTags: ['Organization']
    }),
    verifySubscriptionPayment: builder.mutation({
      query: ({ requestId, body }) => {
        return {
          url: `/organizations/subscriptions/${requestId}/success`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: ['Organization']
    }),
    getTestimonials: builder.query({
      query: () => {
        return {
          url: `/organizations/testimonials`,
          method: 'GET'
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Testimonials']
    }),
    getNewsLetterEmails: builder.query({
      query: () => {
        return {
          url: `/organizations/newsLetterEmails`,
          method: 'GET'
        }
      },
      transformResponse: (response) => response,
      providesTags: ['NewsLetterEmails']
    }),
    addTestimonial: builder.mutation({
      query: (body) => {
        return {
          url: `/organizations/testimonials`,
          method: 'POST',
          body
        }
      },
      transformResponse: (response) => {
        return response
      },
      invalidatesTags: ['Testimonials']
    }),
    deleteTestimonial: builder.mutation({
      query: (testimonialId) => ({
        url: `/organizations/testimonials/${testimonialId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Testimonials']
    }),
    getBanners: builder.query({
      query: () => {
        return {
          url: `/organizations/banners`,
          method: 'GET'
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Banners']
    }),
    addTax: builder.mutation({
      query: (body) => {
        return {
          url: `/organizations/taxes`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Taxes']
    }),
    getTaxes: builder.query({
      query: () => {
        return {
          url: `/organizations/taxes`,
          method: 'GET'
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Taxes']
    }),
    deleteTax: builder.mutation({
      query: (taxId) => ({
        url: `/organizations/taxes/${taxId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Taxes']
    }),
    addStoreBanner: builder.mutation({
      query: (body) => {
        return {
          url: `/organizations/banners`,
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Banners']
    }),
    deleteBanner: builder.mutation({
      query: (bannerId) => ({
        url: `/organizations/banners/${bannerId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Banners']
    }),
    sendPushToken: builder.mutation({
      query: (body) => {
        return {
          url: `/users/push_token`,
          method: 'POST',
          body
        }
      }
    }),
    updateUserProfile: builder.mutation({
      query: (body) => {
        return {
          url: `/users`,
          method: 'PUT',
          body
        }
      }
    }),
    enableCheckout: builder.mutation({
      query: (body) => {
        return {
          url: '/organizations/enableCheckout',
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Organization']
    }),
    disableCheckout: builder.mutation({
      query: (body) => {
        return {
          url: '/organizations/disableCheckout',
          method: 'POST',
          body
        }
      },
      invalidatesTags: ['Organization']
    }),
    deactivateProfile: builder.mutation({
      query: (body) => {
        return {
          url: `/users/deactivate`,
          method: 'POST',
          body
        }
      }
    }),
    deactivateOrg: builder.mutation({
      query: (body) => {
        return {
          url: `/organizations/deactivate`,
          method: 'POST',
          body
        }
      }
    })
  })
})

export const {
  // useLoginMutation,
  // useSignUpMutation,
  useCreateOrgMutation,
  useGetOrgsQuery,
  useGetOrgQuery,
  useLazyGetOrgQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreateUserProfileMutation,
  useUpdateOrgMutation,
  useAddSampleDataMutation,
  useUpdatePaymentOptionsMutation,
  useAddUpdateSubscriptionMutation,
  useVerifySubscriptionPaymentMutation,
  useLazyCheckSlugIsUniqueQuery,
  useLazyGetOrgsQuery,
  useAddStoreBannerMutation,
  useAddTestimonialMutation,
  useGetBannersQuery,
  useGetTestimonialsQuery,
  useSendPushTokenMutation,
  useDeleteBannerMutation,
  useDeleteTestimonialMutation,
  useGetNewsLetterEmailsQuery,
  useLazyGetNewsLetterEmailsQuery,
  useEnableCheckoutMutation,
  useDisableCheckoutMutation,
  useUpdateUserProfileMutation,
  useGetTaxesQuery,
  useDeleteTaxMutation,
  useAddTaxMutation,
  useDeactivateProfileMutation,
  useDeactivateOrgMutation
} = userApi
