/* eslint-disable no-bitwise */
/**
 *
 * Firebase
 *
 */
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from 'firebase/auth'
import 'firebase/database'
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage'
import { store } from '../redux/store'
import { getUUID } from '../utils/FileUtils'

const LOG_TAG = 'Firebase'

const FirebaseConfig = {
  apiKey: 'AIzaSyDZkUW6My355JQv3ZeC1MEwCIIFV7mJB94',
  authDomain: 'nuventis-nustore.firebaseapp.com',
  projectId: 'nuventis-nustore',
  storageBucket: 'nuventis-nustore.appspot.com',
  messagingSenderId: '474465402079',
  appId: '1:474465402079:web:107e1dc0b8782beb76fd6a',
  measurementId: 'G-S7FQ0MN53R'
}

const app = initializeApp(FirebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth()
const storage = getStorage()
export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    const resp = await signInWithEmailAndPassword(auth, email, password)
    // console.log('response: ' + JSON.stringify(resp));
    return resp
  } catch (e) {
    // console.log('Error signin ' + JSON.stringify(e));
    return e
  }
}

export const doSignUpWithEmailAndPassword = async (email, password) => {
  try {
    const resp = await createUserWithEmailAndPassword(auth, email, password)
    // console.log('response: ' + JSON.stringify(resp));
    return resp
  } catch (e) {
    console.log('Error signup ', e)
    return e
  }
}

export const doSignInWithPhoneNumberWithInvisibleCaptcha = async (phoneNumber) => {
  //**Need to render the captcha in the useEffect of the component in which it is called */
  //**The submit button must have an id specified in RecaptchaVerifier */
  try {
    const recaptchaVerifier = window.recaptchaVerifier
    const resp = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
    return resp
  } catch (e) {
    console.log('Error signup ', e)
    return e
  }
}

export const doSignInWithPhoneNumberWithCaptcha = async (phoneNumber) => {
  //**Add an empty div with id recaptcha-container */
  try {
    const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth)
    recaptchaVerifier.render()
    const resp = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
    return resp
  } catch (e) {
    console.log('Error signup ', e)
    return e
  }
}

export const uploadFileAsync = async (data, path, isImage) => {
  if (!data) {
    return
  }
  const fileName = getUUID()
  const storageRef = ref(storage, `${path}/${fileName}`)

  let uploadTask = null
  if (isImage) {
    uploadTask = uploadBytesResumable(storageRef, data, { contentType: 'image/jpeg' })
  } else {
    uploadTask = uploadBytesResumable(storageRef, data)
  }
  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log(`Upload is ${progress}% done`)
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused', LOG_TAG)
            break
          case 'running':
            console.log('Upload is running', LOG_TAG)
            break
          default:
            break
        }
      },
      (error) => {
        // Upload failed with error
        console.log('Error in uploadTask', LOG_TAG)
        switch (error.code) {
          case 'storage/unauthorized':
            console.log('permission not found', LOG_TAG)
            break
          case 'storage/canceled':
            console.log('Upload canceled by user', LOG_TAG)
            break
          case 'storage/unknown':
            console.log('unknown error occurred', LOG_TAG)
            break
          default:
            break
        }
        reject({
          error
        })
      },
      async () => {
        // Upload completed successfully
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
        const metaData = await getMetadata(uploadTask.snapshot.ref)
        console.log('File available at', downloadURL, LOG_TAG)
        console.log('Metadata', JSON.stringify(metaData), LOG_TAG)
        resolve({
          downloadURL,
          metaData
        })
      }
    )
  })
}

export const deletFileFromStorage = (file) => {
  const fileUrl = `gs://${file.bucket_name}/${file.object_name}`
  const fileRef = ref(storage, fileUrl)
  deleteObject(fileRef)
    .then(() => {
      // File deleted successfully
      console.log('Deleted')
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error)
    })
}

export const generateFirestoreDocId = () => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let autoId = ''
  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
  }
  return autoId
}
