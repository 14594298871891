import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BaseURL } from '../../configs/config'

export const configApi = createApi({
  reducerPath: 'configApi',
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: BaseURL + 'config',
    prepareHeaders: async (headers, { getState }) => {
      const orgId = getState().prefs.selectedOrgId
      if (orgId) {
        headers.set('org-id', orgId)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getPaymentConfig: builder.query({
      query: () => 'paymentOptions',
      transformResponse: (response) => response.data
    }),
    getAvailablePlans: builder.query({
      query: (currency) => {
        const params = {}
        if (currency) {
          params.currency_code = currency
        }
        params.readable = true
        return {
          url: '/plans',
          params
        }
      },
      transformResponse: (response) => response.data
    }),
    getBusinessCategories: builder.query({
      query: () => 'businessCategories',
      transformResponse: (response) => response.data
    }),
    getCurrencies: builder.query({
      query: () => 'currencies',
      transformResponse: (response) => response.data
    }),
    getCountries: builder.query({
      query: () => 'countries',
      transformResponse: (response) => response.data
    }),
    getBusinessCardTemplates: builder.query({
      query: () => 'businessCardTemplates',
      transformResponse: (response) => {
        return response.data
      }
    }),
    getBannerImages: builder.query({
      query: () => 'bannerImages',
      transformResponse: (response) => {
        return response.data
      }
    })
  })
})

export const {
  useGetPaymentConfigQuery,
  useGetAvailablePlansQuery,
  useGetBusinessCategoriesQuery,
  useGetCurrenciesQuery,
  useGetBusinessCardTemplatesQuery,
  useGetCountriesQuery,
  useGetBannerImagesQuery
} = configApi
