import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import { userApi } from './services/users'
import { catalogApi } from './services/catalog'
import userReducer from './services/userSlice'
import productVariantsReducer from './services/productVariantsSlice'

import { customerApi } from './services/customer'
import { enquiryApi } from './services/enquiry'
import { orderApi } from './services/orders'
import { configApi } from './services/config'
// import { analyticsApi } from './services/analytics'
import { couponsApi } from './services/coupons'
import { subscriptionApi } from './services/subscriptions'
import { bulkUploadApi } from './services/bulkUpload'
import { paymentOptionsApi } from './services/paymentOptions'
import { shippingApi } from './services/shipping'
import preferenceReducer from './services/preferenceSlice'
import { shippingProviderApi } from './services/shippingProvider'

const persistConfig = {
  key: 'root',
  storage,
  // stateReconciler: autoMergeLevel2,
  // transforms: [
  //   createFilter('userProfile', ['orgId']),
  // ],
  whitelist: ['prefs']
}

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    [catalogApi.reducerPath]: catalogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [enquiryApi.reducerPath]: enquiryApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    // [analyticsApi.reducerPath]: analyticsApi.reducer,
    [couponsApi.reducerPath]: couponsApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [bulkUploadApi.reducerPath]: bulkUploadApi.reducer,
    [paymentOptionsApi.reducerPath]: paymentOptionsApi.reducer,
    [shippingApi.reducerPath]: shippingApi.reducer,
    [shippingProviderApi.reducerPath]: shippingProviderApi.reducer,
    userProfile: userReducer,
    prefs: preferenceReducer,
    productVariants: productVariantsReducer,
    navbar,
    layout
  })
)

export default rootReducer
