import { generateFirestoreDocId } from '../../../firebase/firebase'

const combos = (list, n = 0, result = [], current = []) => {
  if (n === list.length) {
    result.push(current)
  } else {
    list[n].forEach((item) => combos(list, n + 1, result, [...current, item]))
  }
  return result
}

export const createVariantsFromOptions = (ops) => {
  const options = ops.map((o) => {
    const values = o.values
    const updatedVals = values.map((v) => ({
      option_id: o.id || o.option_id,
      option_title: o.option_title || o.title,
      value_id: v.id || v.value_id,
      value_title: v.value_title || v.title
    }))
    o.values = updatedVals
    return o
  })

  const valueCombos = []
  options.forEach((o) => {
    valueCombos.push([...o.values])
  })
  console.log('valueCombos: ' + JSON.stringify(valueCombos))

  const results = combos(valueCombos)
  console.log('result: ' + JSON.stringify(results))

  const variants = results
    .map((r) => {
      return {
        id: generateFirestoreDocId(),
        sku: '',
        name: '',
        price: '',
        discounted_price: '',
        config: r.map((v) => ({
          option_id: v.option_id,
          value_id: v.value_id,
          option_title: v.option_title,
          value_title: v.value_title
        }))
      }
    })
    .filter((p) => {
      return p && Object.keys(p.config).length > 0
    })

  console.log('variants:' + JSON.stringify(variants))

  return variants
}
